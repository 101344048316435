import { baseUrlList } from "./baseUrls";

const baseUrl = () => {
  const { ...allUrls } = baseUrlList;
  return allUrls;
};

// const secondaryUrl = () => {
//     return {
//         auth: 'auth-service/api/v1',
//         terminal: 'terminal-service/api/v1',
//         transactions: 'transaction-service/api/v1',
//         merchant: 'merchant-service/api/v1',
//         audit: 'merchant-service/api/v1',
//         dispute: 'dispute-service/api/v1'
//     }
// }

//Auth service

//Sign in
export const signIn = `${baseUrl().auth}/auth/login`;

//Sign up
export const signUp = `${baseUrl().agent}/registermerchant`;

//validate user
export const validate = `${baseUrl().auth}/auth/validate-user`;

//Delete user
export const toggleLock = `${baseUrl().auth}/user/toggle-lock/`; //useId

//Password reset
export const sendOtp = `${baseUrl().auth}/password/forgot-password/byEmail`;
export const sendOtpChangePassword = `${
  baseUrl().auth
}/password/change-password/byEmail`;
export const resetPassword = `${baseUrl().auth}/password/forgot-password`;
export const changePasswordUrl = `${baseUrl().auth}/password/change-password`;

//Verify OTP
export const generateOtp = `${baseUrl().auth}/auth/resend-otp-mail/`; //{emailOrPhoneNumber}
export const verify = `${baseUrl().auth}/auth/verify-otp`;
export const verifyEmail = `${baseUrl().auth}/auth/verify-email`;

//Resend OTP
export const resend = `${baseUrl().auth}/auth/resend-otp/signup/`;

//Update profile
export const getUserProfile = `${baseUrl().auth}/profile/`; /* userId */
export const getUserById = `${baseUrl().auth}/user/`; /* userId */
export const updateProfilePersonal = `${
  baseUrl().auth
}/profile/update-personal-profile/`;
export const toggle2Fa = ({ userId }) =>
 `${
  baseUrl().auth
}/auth/toggle-2fa?userId=${userId}`;
export const get2FaStatus = ({ userId }) =>
 `${
  baseUrl().auth
}/auth/status-check-2fa/${userId}`;
export const updateProfileCorporate = `${
  baseUrl().agent
}/updatemerchant`; /* userId */
export const updateSettlementAccount = `${
  baseUrl().agent
}/addbankaccount`; /* userId */
export const updateProfileImage = `${
  baseUrl().auth
}/profile/update-profile-image/`; /* userId */
export const updateAgentProfile = ({ userId }) =>
  `${
    baseUrl(userId).agent
  }/checkupdateflag/${userId}`; /* update agent profile */
export const searchContact = `${
  baseUrl().auth
}/search/search-profile-name/`; /* name */
export const searchContactByEmail = `${
  baseUrl().auth
}/search/search-profile-email/`; /* name */
export const searchContactByPhone = `${
  baseUrl().auth
}/search/search-profile-phoneNumber`; /* name */
export const getPosMerchantProfile = `${baseUrl().agent}/details`;

// pin
export const validatePin = `${baseUrl().auth}/pin/validate-pin/`; /* pin */
export const validateUser = `${baseUrl().auth}/auth/validate-user`; /* pin */
export const twoFA = `${baseUrl().agent2fa}/auth/generate-login-otp/`; /* 2fa */
export const pinTokenEmail = `${
  baseUrl().auth
}/pin/create-pin/byEmail`; /* pin */
export const pinTokenPhone = `${
  baseUrl().auth
}/pin/create-pin/byPhone`; /* pin */
export const createPin = `${baseUrl().auth}/pin/create-pin`; /* pin */

// bvn
export const validateBvn = `${baseUrl().kyc}/wayaPos/validateBvn/`; /* token */
export const bvnToken = `${baseUrl().bvn}/bvn/verify/`; /* bvn */
export const linkBVN = `${baseUrl().bvn}/bvn/linkBvn`; /* bvn */

//business types
export const businessTypesList = `${baseUrl().auth}/business/type/find/all`;

//wallet services

export const walletDetails = `${baseUrl().settlement}/wallet/details`;
export const defaultWallet = `${baseUrl().settlement}/wallet/instant/defaultAccount`;
export const bankList = `${baseUrl().settlement}/wallet/getAllBanks`;

export const accountList = `${baseUrl().settlement}/wallet/merchant/bankList`;
export const settlementBank = `${baseUrl().settlement}/wallet/fetchMerchantTerminalAndBank`;

export const addBank = `${baseUrl().settlement}/wallet/addNewBank`;
export const addBankToTerminal = `${baseUrl().settlement}/wallet/attachBankAndTerminal`;
export const deleteBankAccount = `${baseUrl().settlement}/wallet/deleteTerminalAndBank`;
export const updateBankAccount = `${baseUrl().settlement}/wallet/updateTerminalAndBankStatus`;


export const transferFund = `${baseUrl().settlement}/wallet/transferFund`;

export const personalUpdate = `${baseUrl().settlement}/wallet/personalUpdate`;

export const accountHistories = `${
  baseUrl().settlement
}/wallet/merchant/transactionHistories`;

export const initiateTransfer = `${
  baseUrl().settlement
}/wallet/initiateWayaQuickTransaction`;

export const verifyWayaQuickTransfer = `${
  baseUrl().settlement
}/wallet/verifyWayaQuickTransaction`;

//Terminal Services
export const requestTerminal = `${baseUrl().terminal}/terminals/request`;

export const allTerminals = `${
  baseUrl().terminal
}/terminals/viewallterminalbyuser`;

export const allterminalTypes = `${
  baseUrl().terminal
}/terminals/getterminaltypes`;

export const terminalStats = `${baseUrl().terminal}/terminals`;
export const addTerminalPin = `${baseUrl().terminal}/terminals/attach_terminal_pin`;
export const updateTerminalPin = `${baseUrl().terminal}/terminals/update_terminal_pin`;
export const terminalComplaint = `${baseUrl().terminal}/terminals/terminal/complain`;
export const terminalUrl = `${baseUrl().terminal}`;

export const terminalReqStats = `${
  baseUrl().terminal
}/terminals/request/user/analysis`;

export const viewAllRequestedTerminals = `${
  baseUrl().terminal
}/terminals/viewrequestedterminalbyuserid`;

export const searchRequestedTerminalsByParam = `${
  baseUrl().terminal
}/terminals/viewallrequestedterminalbyparam`;

export const searchAllTerminalsByParam = `${
  baseUrl().terminal
}/terminals/viewallterminalbyparam/byMerchant`;

//Merchant Services
export const allMerchants = `${baseUrl().merchant}/agent/viewallmerchants`;

export const getunassignedterminals = `${
  baseUrl().merchant
}/agent/getunassignedterminals`;

export const registerMerchant = `${baseUrl().merchant}/agent/registermerchant`;

export const updateMerchant = `${baseUrl().merchant}/agent/updatemerchant`;

export const mapTerminal = `${baseUrl().merchant}/agent/assignterminal`;

export const unMapTerminal = `${baseUrl().merchant}/agent/unassignterminal`;

export const viewMerchant = `${baseUrl().merchant}/agent/viewmerchantbyid`;

export const merchantDetails = `${baseUrl().merchant}/agent/details`;

export const viewMerchantTerminals = `${
  baseUrl().merchant
}/agent/getmerchantterminals`;

export const activateMerchant = `${baseUrl().merchant}/agent/activatemerchant`;

export const deactivateMerchant = `${
  baseUrl().merchant
}/agent/deactivatemerchant`;

//Transaction Services

export const allTransactions = `${
  baseUrl().transactions
}/transactions/viewalltransactionsbyuser`;
export const allMerchantTransactions = `${
  baseUrl().transactions
}/transactions/viewAllMerchantTransactions`;
export const terminalTransactions = `${
  baseUrl().transactions
}/transactions/gettransactionsbyterminalid`;

export const viewTransaction = `${
  baseUrl().transactions
}/transactions/viewonetransactionsbyuser`;

export const transactionUrl = `${
  baseUrl().transactions
}`;

export const searchTransactionByParam = `${
  baseUrl().transactions
}/transactions/search`;
export const searchTransactionByDate = `${
  baseUrl().transactions
}/transactions/viewAllTransactionsByDate`;
export const transactionStats = `${
  baseUrl().transactions
}/transactions/transactioncount`;
export const agStats = `${
  baseUrl().bankReferal
}/fetchReferralTransactionCommission/stats`;
export const agCustomerStats = `${
  baseUrl().bankReferal
}/fetchCustomerTransactionCommission/stats/`;

export const getAggregators = `${
  baseUrl().bankReferal
}/fetchReferralCustomers?pageNo=1&pageSize=1000`;
export const getAggCommission = `${
  baseUrl().bankReferal
}/fetchReferralTransactionCommissions?pageNo=1&pageSize=1000`;

export const revenueStats = `${
  baseUrl().dispute
}/api/v1/settlement/getTransactionStats/merchant`;

//Activity Logs
export const viewActivity = `${baseUrl().audit}/auditlogs/all/byMerchant`;
export const viewSingleActivity = `${baseUrl().audit}/auditlogs/viewlogbyid`;
export const activityFilter = `${baseUrl().audit}/auditlogs/filter/field`;

//Dispute service
export const wayaDisputesMetrics = `${
  baseUrl().dispute
}/wayaposDisputes/merchant/metrics`;
export const createWayaDispute = `${
  baseUrl().dispute
}/wayaposDisputes/createDispute`;
export const getWayaDispute = `${baseUrl().dispute}/wayaposDisputes/merchant/`; //disputeId
export const getWayaDisputeAttachment = `${baseUrl().dispute}/wayaposDisputes/`; //disputeId/attachment
export const allWayaDisputes = `${
  baseUrl().dispute
}/wayaposDisputes/byMerchant`;
export const resolveWayaDispute = `${
  baseUrl().dispute
}/wayaposDisputes/resolveDispute`;
export const filterWayaDisputes = `${
  baseUrl().dispute
}/wayaposDisputes/filters/byMerchant`; //query string
export const acceptDisputeChargeback = `${
  baseUrl().dispute
}/wayaposDisputes/accept/chargeback/byMerchant`;
export const rejectDisputeChargeback = `${
  baseUrl().dispute
}/wayaposDisputes/reject/chargeback/byMerchant`;
export const otherFile = `${baseUrl().file}/upload/others`; //?fileName=''&userId=

export const allAuthDisputes = `${
  baseUrl().dispute
}/auth-notification-dispute/viewAllDisputes`;
export const allAuthDisputes2 = `${
  baseUrl().dispute
}/auth-notification-dispute/username`;
export const createAuthDisputes = `${
  baseUrl().dispute
}/auth-notification-dispute/createDispute`;
export const deleteAuthDispute = `${
  baseUrl().dispute
}/auth-notification-dispute/delete`;
export const resolveAuthDispute = `${
  baseUrl().dispute
}/auth-notification-dispute/resolveDispute`;
export const downloadAuthAttachment = `${
  baseUrl().dispute
}/auth-notification-dispute/downloadAttachment`;
export const filterAuthDispute = `${
  baseUrl().dispute
}/auth-notification-dispute/viewDisputeByParam`;
export const filterAuthDispute2 = `${
  baseUrl().dispute
}/auth-notification-dispute/merchant/filters`;

export const allOtherDisputes = `${
  baseUrl().dispute
}/othersDispute/viewAllDisputes`;
export const allOtherDisputes2 = `${baseUrl().dispute}/othersDispute/username`;
export const createOtherDispute = `${
  baseUrl().dispute
}/othersDispute/createDispute`; // query string
export const downloadOtherAttachment = `${
  baseUrl().dispute
}/othersDispute/downloadAttachment`;
export const resolveOtherDispute = `${
  baseUrl().dispute
}/othersDispute/resolveDispute`;
export const deleteOtherDispute = `${baseUrl().dispute}/othersDispute/delete`;
export const filterOtherDispute = `${baseUrl().dispute}/othersDispute/filters`;
export const filterOtherDispute2 = `${
  baseUrl().dispute
}/othersDispute/merchant/filters`;

//Settlement
export const allSettlement = `${
  baseUrl().dispute
}/api/v1/settlement/viewusersettlement`;
export const getSettlementDetails = `${
  baseUrl().dispute
}/api/v1/settlement/viewusersettlementbyid`;
export const getSettlementByParam = `${
  baseUrl().dispute
}/api/v1/settlement/search/byMerchant`;
export const settlementStats = `${
  baseUrl().dispute
}/api/v1/settlement/getSettlementStats`;
export const settlementStatistics = `${
  baseUrl().dispute
}/api/v1/settlement/statistics/merchant`;

export const verifyMerchantBankAccount = `${
  baseUrl().settlement
}/wallet/verifyAccountNumber`;
//Settlement
export const kyc = `${baseUrl().dispute}/api/v1/settlement/viewusersettlement`;

// export const getSettlementDetails = `${baseUrl().dispute}/api/v1/settlement/viewusersettlementbyid`;

// export const settlementStats = `${baseUrl().dispute}/api/v1/settlement/getSettlementStats`;

// Withdrawals

export const saveBeneficiary = `${baseUrl().withdrawal}/addWithdrawalBen`;
export const deleteBeneficiary = `${baseUrl().withdrawal}/deleteWithdrawalBen`;
export const getBeneficiary = `${baseUrl().withdrawal}/getWithdrawalBen`; //?userid=userId

export const getProviders = `${baseUrl().withdrawal}/provider/list`;
export const withdraw = `${baseUrl().withdrawal}/withdrawal/fund`;
export const getBankList = `${
  baseUrl().cardService
}/bankAccount/getPaystackBankList`;
export const nameEnquiry = `${
  baseUrl().cardService
}/bankAccount/resolveAccountNumber/`; //param

// funding
export const fundWithCard = `${baseUrl().middleware}/funding/card/charge`;
export const addCard = `${baseUrl().cardService}/card/add`;
export const deleteCard = `${baseUrl().cardService}/card/delete/`; //userId/cardNumber
export const submitCardOtp = `${baseUrl().cardService}/card/submitOtp`;
export const getSavedCards = `${baseUrl().cardService}/card/list/`; // userId

// payments

export const payToEmail = `${
  baseUrl().contact
}/contact/account/service/send/money/to/email/`; //beneficiaryEmail
export const payToPhone = `${
  baseUrl().contact
}/contact/account/service/send/money/to/phoneNumber/`; //beneficiaryNumber
export const payToUserId = `${
  baseUrl().contact
}/contact/account/service/send/money/to/userId/`; //beneficiaryId
export const payToWallet = `${
  baseUrl().contact
}/contact/account/service/send/money/userWallet/to/wallet`; //senderId/senderACNo/beficiaryACNo
// export const payToWallet = `${baseUrl().contact}/contact/account/service/send/money/to/wallet/`; //senderId/senderACNo/beficiaryACNo

//bills

// export const getCategories = `${baseUrl().bills}/api/v1/config/biller`;
export const allCategories = `${baseUrl().bills}/api/v1/config/category`;
export const allBillers = `${baseUrl().bills}/api/v1/config/biller`;
export const getCategories = `${baseUrl().bills}/api/v1/category`;
export const getCategoryBillers = `${baseUrl().bills}/api/v1/category/`; //categoryID
export const payBiller = `${baseUrl().bills}/api/v1/biller/pay`;

//contacts
export const getUserDetailsByEmail = `${baseUrl().auth}/user/email/`; //email

//notifications
export const getUserNotifications = `${
  baseUrl().notifications
}/in-app-notifications/`; //userId
export const filterNotificationByDate = `${
  baseUrl().notifications
}/filter-by-date/`; //userId
export const deleteUserNotifications = `${
  baseUrl().notifications
}/delete-notifications/`; //notificationId
export const toggleRead = `${baseUrl().notifications}/in-app-notifications/`; // userId/notificationId

// corporate roles and access management.
export const getAllRoles = `${baseUrl().role}/role/all`;

export const getUseRole = `${baseUrl().role}/merchant/admin`;

export const createAdmin = `${baseUrl().role}/merchant/createAdminUser`;
export const getAllAdmins = `${baseUrl().role}/merchant/fetchAdmins`;

export const allUnits = `${baseUrl().role}/merchant/roleUnits`;

export const getAllMenu = `${baseUrl().role}/merchant/roleMenus`;

export const sendInvitebyEmail = `${baseUrl().role}/user-role/invite-byEmail`;
export const sendInvitebyPhone = `${
  baseUrl().role
}/user-role/invite-byPhone/{phoneNumber}`;
export const editBusinessOwner = `${baseUrl().role}/business-module/edit`;
export const editRole = `${baseUrl().role}/user-role/edit`;
export const removeRole = `${baseUrl().role}/user-role/delete`;
export const roleFeatures = `${baseUrl().role}/roles`;
export const createCustomRole = `${baseUrl().role}/role/create`;

export const assignRole = `${baseUrl().role}/merchant/assignedMenuToAdmin`;
//  invite.
export const getAllInvites = `${baseUrl().role}/user-access/members/`;

// commission.
export const commissionStats = `${
  baseUrl().commission
}/customerCommission/userId`;

//kyc
export const createUserKyc = `${baseUrl().kyc}/pos/approve/`; //userId
export const approveUserKyc = `${baseUrl().kyc}/pos/approve/`; //userId
export const enableUserKyc = `${baseUrl().kyc}/pos/`; //userId/enable
export const UserKycUpload = `${baseUrl().kyc}/pos/upload/`; //userId/idOption/govtId
export const nipsUrl = `${baseUrl().wayaKyc}/api/v1/kyc/redirect`; //get nips url
export const wayaUpload = `${baseUrl().wayaKyc}/api/v1/kyc/upload/documents/`; //get upload
export const wayaPassUpload = `${baseUrl().wayaKyc}/create/`; //upload passport
export const checkKycStatus = `${baseUrl().wayaKyc}/kyc/isBvnLinked/`; //Check KYC
export const wayaKycStatus = `${baseUrl().wayaKyc}/api/v1/kyc/requirementData/`; //get upload
export const getKycStatus1 = `${baseUrl().wayaKyc}/api/v1/kyc/fetchCurrentLevel/`; //get upload
export const getBusinessList = `${baseUrl().wayaAuth}/business/type/find/all`; //get upload
export const profileUpdate = `${baseUrl().wayaAuth}/profile/update-corporate-profile/`; //get upload
export const wayaKYC = `${baseUrl().wayaKyc}/`; //Check KYC

export const generatePhoneOtp = `${baseUrl().wayaAuth}/auth/resend-otp/`; //getnerate verification otp for phone
export const generateEmailOtp = `${baseUrl().wayaAuth}/auth/resend-otp-mail/`; //generate verification otp for email

export const wayaVerifyPhone = `${baseUrl().wayaAuth}/auth/verify-phone`; //Verify
export const wayaVerifyEmail = `${baseUrl().wayaAuth}/auth/verify-email/`; //Verify




// export const uploadUserFile = `${baseUrl().kyc}/pos/upload/`; //userId  /* /business-logo, cac-certificate, company-address, driver-license, mermart, nin, passport, proof-address, tin, voter-card */
export const getUserKyc = `${
  baseUrl().kyc
}/manage-requirement/list-by-customerId/`; //userId
// export const uploadUserFile = `${baseUrl().kyc}/upload/documents/`; //userId /* multiple files upload */
export const getBusinessTypes = `${
  baseUrl().kyc
}/manage-business-type/list/all`;
export const getTierLimits = `${baseUrl().kyc}/manage-tier/list-by-name/`; //{tierName}
export const getTierRequirements = `${
  baseUrl().kyc
}/user/kyc-requirements/tier`; //{tierName}
export const saveUserKyc = `${baseUrl().kyc}/manage-requirement/customer/add`;
export const getUserKycLevel = `${
  baseUrl().wayaKyc
}/api/v1/kyc/fetchCurrentLevel/`; //{userId}
export const getUserKycStatus = `${baseUrl().kyc}/wayaPos/getKycStatus/userId/`; //{userId}
// Kyc upload
export const uploadUserFile = `${baseUrl().kyc}/wayapos/upload/`; //{userId/name}
// export const toggleRead = `${baseUrl().notifications}/in-app-notifications/`; // userId/notificationId
export const uploadWayaFile = `${baseUrl().kyc}/wayapos/upload/`; //{userId/name}

export const UserKycStatus = `${baseUrl().kyc}/pos/approve/`; //userId

// referral.
export const userReferralCode = `${baseUrl().referral}/referral-code`;
export const allReferrals = `${
  baseUrl().referral
}/get-users-that-have-been-referred`;
export const getReferralEarning = `${
  baseUrl().referral
}/get-users-total-referral-earnings`;

// settings
export const getNotificationsSettings = `${
  baseUrl().settings
}/notification/setting`;
export const setSmsStatus = `${baseUrl().settings}/notification/`; //sms || email
export const settings = `${baseUrl().settings}/setting`; //settings
