import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
// import { CompatRouter } from 'react-router-dom-v5-compat';
import { NotificationContainer } from 'react-notifications';
import { Provider } from 'react-redux';
// import LandingPage from './components/pages/LandingPage';
// import WayapayProducts from './components/pages/Wayapay';

import TransactionHistory from './components/pages/Wayapay/TransactionHistory';
// import AllCategories from './components/pages/Wayapay/AllCategories';
// import Contacts from './components/pages/Wayapay/Contacts';
import Notifications from './components/pages/Notification';
import ComingSoon from './components/pages/ComingSoon';
import Wayagram from './components/pages/Wayagram';
import Interest from './components/pages/Wayagram/Interest';
import Dispute from './components/pages/Wayagram/Dispute';
import Commission from './components/pages/Wayagram/commission';
import WayagramFollowing from './components/pages/Wayagram/Following';
import WayagramProfile from './components/pages/Wayagram/Profile';
import WayagramGroup from './components/pages/Wayagram/Group';
import WayagramPage from './components/pages/Wayagram/Page';
import 'react-notifications/lib/notifications.css';
import './assets/stylesheets/index.scss';
import './pos/App.scss'
import VerifyEmail from './components/pages/VerifyEmail';
import Setting from './components/pages/Setting';
import Activity from './components/pages/Activity';
import CorporateSetting from './components/pages/CorporateSetting';
import Logout from './components/pages/Logout';
import Login from './components/pages/Auth/login';
import CorpLogin from './components/pages/Auth/corpAuth';
import Signup from './components/pages/Auth/signup';
// import ErrorBoundary from './ErrorBoundary';
import ProtectedRoute from './utils/ProtectedRoute';
import CommentView from './components/shared/Cards/CommentView';
import Discover from './components/pages/Wayagram/Discover';
import Chat from './components/pages/Chat';
import { PaymentContextProvider } from './store/context/PaymentContext';
import { ProfileContextProvider } from './store/context/ProfileContext';
import { WalletContextProvider } from './store/context/WalletContext';
import { BillerContextProvider } from './store/context/BillerContext';
import { CardContextProvider } from './store/context/CardContext';
import { WayagramContextProvider } from './store/context/WayagramContext';
import { ModalContextProvider } from './store/context/ModalContext';
import { LayoutContextProvider } from './store/context/LayoutContext';
// import MyGroups from './components/pages/Wayagram/MyGroDisputeDisputeDisputeDisputeups';
import MyGroups from './components/pages/Wayagram/MyGroups';

import ManagePages from './components/pages/Wayagram/ManagePages';
import Bookmarks from './components/pages/Wayagram/Bookmarks';
import Wallet from './components/pages/Wayapay/Wallet';
import TerminalPos from './components/pages/Wayapay/TerminalPos';
// import WebPos from './components/pages/Wayapay/WebPos';
import 'antd/dist/antd.css';
import TerminalPosSettings from './components/pages/Setting/TerminalPos';
import WebPosSetting from './components/pages/Setting/WebPos';
import { NotificationContextProvider } from './store/context/NotificationContext';
// import TeamMembers from './components/pages/Setting/TeamMembers';
// import ManageRoles from './components/pages/Setting/ManageRoles';
import SupportPage from './components/pages/Support';
import ReferalTable from './components/pages/Setting/ReferalTable';
import ApiDocumentation from './components/pages/Setting/apiDocumention';
import store from './store/redux-manager/store';
import SupportButton from './components/shared/buttons/supportButton';
import Cards from './components/pages/Wayapay/cards';
import Invoice from './components/pages/invoice';
import LoanRequest from './components/pages/loanRequest';
import Dashboard from "./pos/pages/Dashboard/Dashboard";
import Terminal from "./pos/pages/Terminals/Terminal";
import AddTerminal from "./pos/pages/Terminals/AddTerminal";
import TerminalRequest from "./pos/pages/Terminals/TerminalRequest";
import PosTransaction from "./pos/pages/Transactions/Transaction";
import PosTransactions from "./pos/pages/Transactions/Transactions";
import Settlement from "./pos/pages/Settlement/Settlement";
import ViewSettlement from "./pos/pages/Settlement/ViewSettlement";
import {SettlementPreference as Preference} from "./pos/pages/Settings/pages/SettlementPreference/SettlementPrefence";
import PosActivity from "./pos/pages/Activity/Activity";
import ReferralsTransactions from './components/pages/Wayagram/commission/transactions';
import TerminalTransaction from './pos/components/TransactionComponents/terminalTransactionHistory';
import AccountStatement from './components/pages/Setting/AccountStatement';


const PrivateRoute = ({ children }) => {
  // console.log(JSON.parse(localStorage.getItem('userDetails')))
  return localStorage.getItem("userDetails") ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

const App = () => (
  <>
    <Provider store={store}>
      {/* <ErrorBoundary> */}
      <NotificationContextProvider>
        <BillerContextProvider>
          <PaymentContextProvider>
            <CardContextProvider>
              <ProfileContextProvider>
                <WalletContextProvider>
                  <ModalContextProvider>
                    <WayagramContextProvider>
                      <LayoutContextProvider>
                        {/* <Route
                          exact
                          path="/products"
                          element={<WayapayProducts}
                        /> */}
                          <BrowserRouter>
                          <Routes>
                            {/* <NotificationContainer /> */}
                            {/* <CompatRouter> */}
                            {/* <Switch> */}
                            <Route exact path="/" element={<Login />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/corp-login" element={<CorpLogin />} />
                            <Route path="/signup" element={<Signup />} />
                            <Route
                              exact
                              path="/wallet"
                              element={<Wallet />}
                            />
                            <Route
                              exact
                              path="/cards"
                              element={<Cards />}
                            />
                            <Route
                              exact
                              path="/transactions"
                              element={<TransactionHistory />}
                            />
                            <Route
                              path="/dashboard"
                              exact
                              element={<Dashboard />}
                            />
                            <Route
                              path="/terminals"
                              exact
                              element={<Terminal />}
                            />
                            <Route
                              path="/terminal-transactions/:terminalId"
                              exact
                              element={<TerminalTransaction />}
                            />
                            <Route
                              exact
                              path="/notifications"
                              element={<Notifications />}
                            />
                            <Route
                              exact
                              path="/settings/team-members"
                              // element={<TeamMembers}
                              element={<ComingSoon />}
                            />
                            <Route
                              path="/add-terminal"
                              exact
                              element={<AddTerminal />}
                            />
                            <Route
                              path="/terminal-requests"
                              exact
                              element={<TerminalRequest />}
                            />
                            <Route
                              path="/pos-transactions"
                              exact
                              element={<PosTransactions />}
                            />
                            <Route
                              path="/settlements"
                              element={<Settlement />}
                            />

                            <Route
                              path="/settlement/:id"
                              element={<ViewSettlement />}
                            />
                            <Route
                              path="/settings/pos"
                              element={<Preference />}
                            />
                            <Route
                              path="/pos-activity-log/:terminalId"
                              element={<PosActivity />}
                            />
                            
                            {/* <Route
                              exact
                              path="/settings/team-members/manage-roles"
                              element={<ManageRoles}
                            /> */}
                            {/* <Route
                            exact
                            path="/s_notifications"
                            element={<Notifications}
                            /> */}
                            <Route
                              exact
                              path="/dispute"
                              element={<Dispute />}
                              // element={<ComingSoon}
                            />
                            <Route
                              exact
                              path="/support"
                              element={<SupportPage />}
                            />
                            {/* <Route
                              exact
                              path="/profile/settings"
                              element={<Setting}
                            /> */}
                            <Route
                              exact
                              path="/corporate-settings"
                              element={<CorporateSetting />}
                            />
                            <Route
                              exact
                              path="/settings/invoice"
                              // element={<TeamMembers}
                              element={<Invoice />}
                            />
                            <Route
                              exact
                              path="/chat"
                              element={<Chat />}
                            />

                            <Route
                              exact
                              path="/wayagram"
                              element={<Wayagram />}
                            />
                            <Route
                              path="/settings"
                              element={<Setting />}
                            />
                             <Route
                                path="/settings/account/statement"
                                element={<AccountStatement />}
                              />
                            <Route
                              exact
                              path="/interest"
                              element={<Interest />}
                            />
                            <Route
                              exact
                              path="/post/:id"
                              element={<CommentView />}
                            />
                            <Route
                              exact
                              path="/following/:id"
                              element={<WayagramFollowing />}
                            />
                            <Route
                              exact
                              path="/wayagram-profile/:id"
                              element={<WayagramProfile />}
                            />
                            <Route
                              exact
                              path="/wayagram-group/:id"
                              element={<WayagramGroup />}
                            />
                            <Route
                              exact
                              path="/activity-log"
                              element={<Activity />}
                              //  element={<ComingSoon}
                            />
                            <Route
                              exact
                              path="/loan-request"
                              element={<LoanRequest />}
                            />
                            <Route
                              exact
                              path="/wayagram-page/:id"
                              element={<WayagramPage />}
                            />
                            {/* <Route exact path="/single-post/:id" element={<Wayapay} /> */}
                            <Route
                              exact
                              path="/manage-groups"
                              element={<MyGroups />}
                            />
                            <Route
                              exact
                              path="/manage-pages"
                              element={<ManagePages />}
                            />
                            <Route
                              exact
                              path="/wayagram-discover"
                              element={<Discover />}
                            />
                            <Route
                              exact
                              path="/wayagram-bookmarks"
                              element={<Bookmarks />}
                            />
                            <Route
                              exact
                              path="/terminalPos-settings"
                              element={<TerminalPosSettings />}
                            />
                            <Route
                              exact
                              path="/commission"
                              element={<Commission />}
                            />
                            <Route
                              exact
                              path="/referrals-transaction"
                              element={<ReferralsTransactions />}
                            />
                            <Route
                              exact
                              path="/referal-table"
                              element={<ReferalTable />}
                            />
                            <Route
                              exact
                              path="/api-documentation"
                              element={<ApiDocumentation />}
                            />
                            <Route
                              exact
                              path="/webPos-settings"
                              element={<WebPosSetting />}
                            />
                            <Route
                              exact
                              path="/verify_email"
                              element={<VerifyEmail />}
                            />
                            <Route exact path="/logout" element={<Logout />} />
                          </Routes>
                          </BrowserRouter>
                        </LayoutContextProvider>
                      </WayagramContextProvider>
                    </ModalContextProvider>
                  </WalletContextProvider>
                </ProfileContextProvider>
              </CardContextProvider>
            </PaymentContextProvider>
          </BillerContextProvider>
        </NotificationContextProvider>
        {/* </Switch> */}
      <SupportButton />
      {/* </ErrorBoundary> */}
    </Provider>
  </>
);

export default App;
