import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Switch } from "@mui/material";
import "./styles.scss";
import { DropDownFilter } from "../../../../components/UI/DropDownFilter";
import { addBank, addBankToTerminal, bankList, updateBankAccount, verifyMerchantBankAccount } from "../../../../plugins/urls";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Slide, toast } from "react-toastify";
import _ from "lodash";
import { getUser } from "../../../../utilities/objectOperations";
import { blurHandler } from "../../../../utilities/handlers";
import Swal from "sweetalert2";

export const UpdateTerminalBank = ({ open, onClose, setOpen, terminals, accountsList, bankTerminalId }) => {
  const [formData, setFormData] = useState({
    accountNumber: null,
    accountName: "",
  });
  const [optionLists, setoptionLists] = useState([]);
  const user = getUser();

  const [sel, setSel] = useState("Select terminal");
  const [selAccount, setSelAccount] = useState("Select account");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);

  const handleClick = (e) => {
    e.preventDefault();
    if (sel && selAccount) {
      setLoading(true);
      const reqBody = {        
      };
      axios
        .put(`${updateBankAccount}?defaultFlag=${status}&personWalletTerminalId=${selAccount}`)
        .then((res) => {
            Swal.fire({
                title: "",
                text: res.data.respDesc,
                icon: "",
                confirmButtonText: "Ok",
            });
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
          setOpen(false);
          setFormData({});
        });
    } else {
      toast.error(
        "All fields are required",
        {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        }
      );
    }
  };

  const validations = {
    accountNumber: { required: true, maxLength: 10, minLength: 9 },
    accountName: {
      required: true,
      pattern: /^([^0-9]*)$/,
    },
  };

  return (
    <Dialog open={open} onClose={onClose} className={"addBank__dialog"}>
      <DialogTitle>{"ADD BANK ACCOUNT FOR SETTLEMENT"}</DialogTitle>
      <DialogContent>
        <form>
          {/* <div className="addBank__dialog_dropdown">
            <label>Select terminal</label>
            <select
              onChange={(e) => setSel(e.target.value)}
              value={sel}
            >
              <option>Select terminal</option>
              {terminals?.map((e) =>
                <option value={e?.id} key={e?.id}>
                  {e?.actualTerminalName}
                </option>
              )}
            </select> */}
            {/* <DropDownFilter
              sel={sel}
              setSel={setSel}
              optionList={terminals}
              keyVal={{ key: "id", val: "id" }}
            /> */}
          {/* </div> */}
          <div className="addBank__dialog_dropdown">
            <label>Select Terminal</label>
            <select
              onChange={(e) => setSelAccount(e.target.value)}
              value={selAccount}
            >
              <option>Select account</option>
              {accountsList?.map((e) =>
                <option value={e?.bankTerminalId} key={e?.bankTerminalId}>
                  {e?.accountNumber}-{e?.accountName}
                </option>
              )}
            </select>
            {/* <DropDownFilter
              sel={selAccount}
              setSel={setSelAccount}
              optionList={accountsList}
              keyVal={{ key: "id", val: "id" }}
            /> */}
          </div>
          <div className="addBank__dialog_dropdown">
            <label>set Default</label>
            <select
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value={0}>False</option>
              <option value={1}>True</option>              
            </select>
          </div>

          <div className="btn-group">
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
              }}
            >
              {"Cancel"}
            </button>
            <button
              className="button"
              disabled={!selAccount || !sel}
              onClick={handleClick}
            >
              {"Update bank Account"}
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
