import axios from "axios";
import { accountList, settlementBank, allTerminals, updateBankAccount, deleteBankAccount } from "../../../../plugins/urls";
import { useEffect, useState } from "react";
import { Badge, Table } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AddBank } from "./AddBank";
import "./SettlementPreferenceStyles.scss";
import { apiCall } from "../../../../utilities/apis";
import { AddBankToTerminal } from "./AddBankToTerminal";
import { UpdateTerminalBank } from "./updateTerminalBank";
import { Delete } from "@mui/icons-material";
import Swal from "sweetalert2";

export const TerminalPrefenceTable = () => {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));

  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [accountListDt, setAccountListDt] = useState([]);
  const [terminals, setTerminals] = useState([]);
  const [accountDt, setAccountDt] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getSettlementList = () => {
    axios.get(settlementBank).then((res) => {
      if (res.status === 200) {
        setAccountListDt(res?.data?.respBody?.data);
      }
    });
  };
  const deleteBank = (id) => {
    axios.delete(`${deleteBankAccount}?personWalletTerminalId=${id}`).then((res) => {
      if (res.status === 200) {
        Swal.fire(res.data.respDesc, "", "");
      }
    });
  };
  const userTerminals = () =>
    apiCall({
      url: `${allTerminals}`,
      data: {
        pageSize: 100,
        pageNo: 1,
        paramList: [
          {
            userid: user?.id,
          },
        ],
      },
      defaultMeta: true,
      // setLoading: setLoadingWal,
      success: (data) => {
        setTerminals(data.content);
        // setTeminalNo(data?.content[0]?.accountNo);
      },
      method: "post",
    });

    const getAccountList = async () => {
      axios.get(accountList).then(
        (res) => {
        setAccountDt(res.data.respBody);
      })
    };
    
  useEffect(() => {
    getAccountList();
    userTerminals();
    getSettlementList();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  return (
    <div>
      <div className="container header">
        <button className="button" onClick={handleClickOpen}>
          {"Add Bank To A Terminal"}
        </button>
      </div>
      {/* display terminal settelemnt banks */}
      <Table>
        <thead>
          <tr className="t-head-colored tr">
            <th>Terminal Name</th>
            <th>Terminal ID</th>
            <th>Account Name</th>
            <th>Account Number</th>
            <th>Status</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {accountListDt?.map((dt) => {
            const {terminalName, accountNumber, terminalId, accountName, makePrimary, bankTerminalId, activeStatus } = dt;

            return (
              <tr>
                <td>{terminalName}</td>
                <td>{terminalId}</td>
                <td>
                  {accountName}
                  {makePrimary && (
                    <Badge style={{ marginLeft: "4px" }} pill bg="primary">
                      {"Default"}
                    </Badge>
                  )}
                </td>
                <td>{accountNumber}</td>
                <td>{activeStatus ? 'TRUE' : 'FALSE'}</td>
                <td>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <button
                      className="button btn-sm d-flex align-items-center"
                      onClick={() => {
                        setOpenUpdate(true);
                      }}
                    >
                      {/* <RiDeleteBin6Line className="white" /> */}
                      {"Update"}
                    </button>
                      <Delete onClick={() => deleteBank(bankTerminalId)} className="white" />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <AddBankToTerminal open={open} onClose={handleClose} setOpen={setOpen} terminals={terminals} accountsList={accountDt} />
      <UpdateTerminalBank open={openUpdate} onClose={handleCloseUpdate} setOpen={setOpenUpdate}  terminals={terminals} accountsList={accountListDt} />
      <div className="mt-5">
        <p>
          <h4 className="fw-bold">NB:</h4>
          <div>
            <ul>
              <li>
                <h6>
                  Choosing Wayabank as your default settlement account means that your transactions will be settled instantly.
                </h6>
              </li>
              <li>
                <h6>
                Choosing an external bank as your default settlement account, means that your settlement will be T+1 (Next Day) or weekly.
                </h6>
              </li>
              <li>
               <h6>
                if you have chosen another bank as your default settlement account, please kindly go to the Payment Preferences page to update your settlement preference to either T+1 (Next Day) or weekly.
                </h6>
              </li>
            </ul>
          </div>
        </p>
      </div>
    </div>
  );
};
